var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gift-exchange-user-card relative w-full h-full" },
    [
      _c("AppUserCard", {
        staticClass: "relative w-full h-full",
        attrs: { user: _vm.user },
      }),
      _c(
        "div",
        { staticClass: "gift-exchange-user-card__ui-container" },
        [
          _vm.user.present
            ? _c("GiftExchangeUI", {
                staticClass: "gift-exchange-user-card__ui",
                attrs: {
                  gift: _vm.user.present,
                  steals: _vm.user.steals,
                  canSteal: _vm.canSteal,
                },
                on: {
                  stealPresent: function ($event) {
                    return _vm.$emit("stealPresent", _vm.user.present)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }