var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "white-elephant", attrs: { row: "" } },
    [
      _vm.showPresents
        ? _c("v-flex", { staticClass: "white-elephant__sidebar" }, [
            _c("h2", { staticClass: "white-elephant__title" }, [
              _vm._v("Virtual Gift "),
              _c("br"),
              _vm._v("Exchange"),
            ]),
            _c("div", { staticClass: "white-elephant__presents" }, [
              _c("div", { staticClass: "present-grid" }, [
                _c(
                  "div",
                  { staticClass: "present-grid__cnt" },
                  _vm._l(_vm.presents, function (present, index) {
                    return _c(
                      "div",
                      {
                        key: present.label + "-" + index,
                        staticClass: "present-grid__item",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "present-grid__item-cnt" },
                          [
                            _c("SvgIcon", {
                              staticClass: "present",
                              class: {
                                "present--selectable": _vm.isPresentClickable,
                              },
                              attrs: { name: present.icon },
                              on: {
                                click: function ($event) {
                                  return _vm.selectPresent(present)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "v-flex",
        { class: _vm.showPresents ? "xs10" : "xs12", attrs: { "d-flex": "" } },
        [
          _c("div", { staticClass: "white-elephant-grid__scrollable" }, [
            _c(
              "div",
              { ref: "grid", staticClass: "white-elephant-grid__grid" },
              [
                _c(
                  "div",
                  {
                    key: "first",
                    staticClass: "white-elephant-grid__sqaure",
                    style: _vm.getStyle(3),
                  },
                  [
                    _c("MeetingCard", {
                      attrs: {
                        endTime: _vm.endTime,
                        currentPresent: _vm.presentToDisplay,
                        cardText: _vm.message,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button",
                          fn: function () {
                            return [
                              _vm.isMissionCompleted && !_vm.overrideMessage
                                ? _c(
                                    "div",
                                    { staticClass: "pb-3" },
                                    [
                                      _c(
                                        "RtbButton",
                                        {
                                          staticClass:
                                            "ml-auto mr-auto white-elephant__download-btn",
                                          attrs: { size: "lg" },
                                          on: { click: _vm.download },
                                        },
                                        [_vm._v(" Download results ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._l(_vm.playersWithPresents, function (player) {
                  return _c(
                    "div",
                    {
                      key: "grid-item-" + player.id,
                      staticClass: "white-elephant-grid__sqaure px-2 py-2",
                      class: player.current
                        ? "white-elephant-grid__sqaure--selected"
                        : null,
                      style: _vm.getStyle(),
                    },
                    [
                      _c("GiftExchangeUserCard", {
                        attrs: {
                          user: player,
                          canSteal: player.stealable && !_vm.overrideMessage,
                        },
                        on: { stealPresent: _vm.stealPresent },
                      }),
                    ],
                    1
                  )
                }),
                _c("ResizeObserver", { on: { notify: _vm.handleResize } }),
              ],
              2
            ),
          ]),
          !!_vm.mission.youtube
            ? _c(
                "Popup",
                {
                  attrs: { isOpen: _vm.dialogVisibility, closable: false },
                  on: {
                    onClose: function ($event) {
                      _vm.dialogVisibility = false
                    },
                    onClickOutside: function ($event) {
                      _vm.dialogVisibility = false
                    },
                  },
                },
                [
                  _vm.dialogVisibility
                    ? _c(
                        "div",
                        { staticClass: "white-elephant__end-video" },
                        [
                          _c("VideoComponent", {
                            attrs: { src: _vm.mission.youtube, autoplay: "" },
                            on: {
                              ended: function ($event) {
                                _vm.dialogVisibility = false
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }