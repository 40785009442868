

























































import Vue from "vue"
import { RtbButton } from "@/components/ui"
const MAX_N_OF_STEALS = 2
export default Vue.extend({
  name: "GiftExchangeUI",
  components: { RtbButton },
  props: {
    gift: {
      type: Object,
      required: true
    },
    canSteal: {
      type: Boolean,
      default: false
    },
    maxSteals: {
      type: Number,
      default: MAX_N_OF_STEALS
    },
    steals: {
      type: Number,
      default: undefined
    }
  },
  methods: {
    zoom() {
      this.$bus.$emit("zoomMissionAssetPhoto", {
        visible: true,
        photo: this.gift.image,
        title: this.gift.label
      })
    }
  }
})
