<template>
  <v-layout row class="white-elephant">
    <v-flex v-if="showPresents" class="white-elephant__sidebar">
      <h2 class="white-elephant__title">Virtual Gift <br />Exchange</h2>
      <div class="white-elephant__presents">
        <div class="present-grid">
          <div class="present-grid__cnt">
            <div
              class="present-grid__item"
              v-for="(present, index) in presents"
              :key="`${present.label}-${index}`"
            >
              <div class="present-grid__item-cnt">
                <SvgIcon
                  class="present"
                  :name="present.icon"
                  :class="{ 'present--selectable': isPresentClickable }"
                  @click="selectPresent(present)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-flex>
    <v-flex d-flex :class="showPresents ? 'xs10' : 'xs12'">
      <div class="white-elephant-grid__scrollable">
        <div class="white-elephant-grid__grid" ref="grid">
          <div
            key="first"
            class="white-elephant-grid__sqaure"
            :style="getStyle(3)"
          >
            <MeetingCard
              :endTime="endTime"
              :currentPresent="presentToDisplay"
              :cardText="message"
            >
              <template #button>
                <div class="pb-3" v-if="isMissionCompleted && !overrideMessage">
                  <RtbButton
                    size="lg"
                    class="ml-auto mr-auto white-elephant__download-btn"
                    @click="download"
                  >
                    Download results
                  </RtbButton>
                </div>
              </template>
            </MeetingCard>
          </div>
          <div
            v-for="player in playersWithPresents"
            :key="`grid-item-${player.id}`"
            class="white-elephant-grid__sqaure px-2 py-2"
            :class="
              player.current ? 'white-elephant-grid__sqaure--selected' : null
            "
            :style="getStyle()"
          >
            <GiftExchangeUserCard
              :user="player"
              :canSteal="player.stealable && !overrideMessage"
              @stealPresent="stealPresent"
            />
          </div>
          <ResizeObserver @notify="handleResize" />
        </div>
      </div>
      <Popup
        v-if="!!mission.youtube"
        :isOpen="dialogVisibility"
        :closable="false"
        @onClose="dialogVisibility = false"
        @onClickOutside="dialogVisibility = false"
      >
        <div class="white-elephant__end-video" v-if="dialogVisibility">
          <VideoComponent
            @ended="dialogVisibility = false"
            :src="mission.youtube"
            autoplay
          />
        </div>
      </Popup>
    </v-flex>
  </v-layout>
</template>

<script>
import Vue from "vue"
import _ from "lodash"
import { mapGetters, mapState } from "vuex"
import File from "@/services/file.service"
import { debounce } from "lodash"
import { ResizeObserver } from "vue-resize"
import fit from "@shared/helpers/fit"

import GiftExchangeUserCard from "@/components/GroupTeams/Common/Player/GiftExchangeUserCard/GiftExchangeUserCard"

import MeetingCard from "@/components/GroupTeams/Common/Player/MeetingCard"
import { RtbButton } from "@/components/ui"
import Mission from "@shared/enums/Mission"
import User from "@shared/User"
import NavigationService from "@/services/navigation.service"
import Popup from "@/components/Popups/Popup"
import VideoComponent from "@/components/ui/VideoComponent/VideoComponent.vue"

const MIN_LENGTH = 160
const MAX_LENGTH = 600

function getPlayerName(user) {
  return `${user?.firstname} ${user?.lastname?.[0]}.`?.toUpperCase()
}

export default Vue.extend({
  name: "WhiteElephantGrid",
  components: {
    GiftExchangeUserCard,
    VideoComponent,
    ResizeObserver,
    RtbButton,
    Popup,
    MeetingCard
  },
  props: {
    maxLength: {
      type: Number,
      default: MAX_LENGTH,
      required: false
    },
    minLength: {
      type: Number,
      default: MIN_LENGTH,
      required: false
    }
  },
  data() {
    return {
      width: 0,
      height: 0,
      overrideMessage: null,
      timeout: null,
      dialogVisibility: false,
      working: false,
      currentUserID: null
    }
  },
  computed: {
    ...mapGetters(["gameStatus", "moderatorID", "game"]),
    ...mapState(["gameID", "orgID"]),
    ...mapGetters("soundeffect", ["volume"]),
    ...mapGetters("auth", ["user", "isHost"]),
    ...mapGetters({
      users: "onlineUsersArray",
      missionID: "currentMission",
      plays: "missionPlaysArray",
      mission: "getCurrentMission"
    }),
    showPresents() {
      return this.presents.length > 0
    },
    isPresentClickable() {
      return !!(
        (this.isCurrentUser || this.isHost) &&
        !this.working &&
        this.currentUser?.id &&
        !this.overrideMessage
      )
    },
    presentToDisplay() {
      if (this.isMissionCompleted) return undefined
      if (!this.overrideMessage) return undefined

      return this.currentPresent
    },
    endTime() {
      if (!this.game?.autopilot) return 0
      const int = parseInt(this.gameStatus?.endTime) || 0
      const endTime = int - Date.now()
      return endTime > 0 ? endTime : 0
    },
    userID() {
      return this.user.id
    },
    players() {
      const moderatorID = this.moderatorID
      const isUserAlive = this.$store.getters.isUserAlive
      return this.users
        .filter(
          user =>
            User.isSelectable(user) &&
            user.id !== moderatorID &&
            isUserAlive(user)
        )
        .sort(User.sort)
    },
    currentPlay() {
      return this.plays.sort((a, b) => b.timestamp - a.timestamp)[0]
    },
    currentPresent() {
      return this.currentPlay?.answer
    },
    playersWithPresents() {
      const plays = this.plays
      const currentUserID = this.currentUserID
      const isCurrentUser = currentUserID === this.user?.id
      const players = this.players.map(user => {
        const play = plays.find(play => play.userID === user.id) || {}
        return {
          ...user,
          present: play?.answer,
          stolenFromUserID: play?.stolenFromUserID,
          steals: play?.steals,
          current: currentUserID === user?.id,
          stealable:
            isCurrentUser && play && play.stolenFromUserID !== currentUserID
        }
      })

      const winnerID = this.raffleWinnerID
      const winner = winnerID && players.find(user => user.id === winnerID)

      if (winner && !this.isRaffleInProgress) {
        return [winner, ...players.filter(user => user.id !== winnerID)]
      }

      return players
    },
    isCurrentUser() {
      return this.currentUser?.id === this.user?.id
    },
    currentUserIDReceived() {
      return this.gameStatus?.whiteElephantUserID
    },
    currentUser() {
      console.log("GAME STATUS", this.gameStatus)
      if (this.isRaffleInProgress) return undefined
      const currentUserID = this.currentUserID
      return (
        this.playersWithPresents.find(user => user.id === currentUserID) || {}
      )
    },
    presents() {
      const images = this.mission?.whiteElephantImages
      const array = Array.isArray(images) ? images : []
      const plays = this.plays

      return array
        .map((present, index) => ({
          ...present,
          icon: `present-${(index % 5) + 1}`
        }))
        .filter(
          present => !plays.some(play => play.answer?.image === present?.image)
        )
        .slice(0, 13)
        .filter(present => present)
    },
    length() {
      const { width, height } = this
      if (width === 0 || height === 0) return 0
      const items = this.users
      const nOfUsers = items.length + 2 // for the white elephant mission edition
      if (!nOfUsers) return 0
      const n = fit(width, height, nOfUsers) - 1
      return Math.max(this.minLength, Math.min(n, this.maxLength))
    },
    raffleWinnerID() {
      return this.gameStatus?.raffle?.winner
    },
    isRaffleInProgress() {
      return !!this.gameStatus?.raffle?.active
    },
    message() {
      if (this.overrideMessage) {
        return this.overrideMessage
      } else if (this.isMissionCompleted) {
        return `That's all folks! <br> Now you can download the results.`
      } else if (this.isCurrentUser) {
        const nOfPlays = this.plays.length
        let message = ""

        if (nOfPlays === 0) message += `Welcome to the game! <br>`

        const name = getPlayerName(this.currentUser)

        message += `${name} it's your turn to select a present from the left.`

        if (nOfPlays) {
          message += ` or steal from another player. <br>
          <span class="body-1 blue--text text--lighten-4 mt-4">
            Gifts can only be stolen twice.
          </span>
        `
        }

        return message
      } else if (this.currentUser?.id) {
        if (this.isHost) {
          return `
            ${getPlayerName(this.currentUser)} is selecting a present...
            <br>
            <span class="body-1 blue--text text--lighten-4 mt-4">
              You can select it for them by pressing the spacebar.
            </span>
          `
        } else {
          return `${getPlayerName(this.currentUser)} is selecting a present...`
        }
      } else if (this.isHost) {
        return "Press the spacebar to continue."
      } else {
        return "Welcome to Virtual Gift Exchange!"
      }
    },
    // mission is considered to be completed when all users got their presents
    isMissionCompleted() {
      return (
        this.plays.length &&
        (this.playersWithPresents.every(user => user.present) ||
          this.presents.length === 0)
      )
    }
  },
  watch: {
    isCurrentUser: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) this.speak(`it's your turn`)
      }
    },
    currentUserIDReceived: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          setTimeout(() => {
            this.currentUserID = newValue
          }, 5000)
        }
      }
    },

    currentPlay: {
      immediate: true,
      handler(newValue, oldValue) {
        let overrideMessage

        if (
          newValue?.stolenFromUserID &&
          newValue?.stolenFromUserID !== oldValue?.stolenFromUserID
        ) {
          const present = newValue?.answer?.label || "amazing gift"
          const users = this.players
          const thief = users.find(user => user.id === newValue.userID)
          const loser = users.find(
            user => user.id === newValue.stolenFromUserID
          )

          if (thief && loser) {
            overrideMessage = `${getPlayerName(
              thief
            )} has stolen ${present} from ${getPlayerName(loser)}`
          }
        } else if (newValue?.id && newValue?.id !== oldValue?.id) {
          const present = newValue?.answer?.label || "amazing gift"
          const users = this.players
          const user = users.find(user => user.id === newValue?.userID)

          if (user) {
            overrideMessage = `${getPlayerName(user)} has selected ${present}`
          }
        }

        if (overrideMessage) {
          clearTimeout(this.timeout)
          this.overrideMessage = overrideMessage
          this.timeout = setTimeout(() => {
            this.overrideMessage = null
          }, 6000)
        }
      }
    },
    isMissionCompleted(value) {
      if (value && this.mission?.youtube) {
        setTimeout(() => {
          this.dialogVisibility = true
        }, 3000)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.grid?.offsetHeight || 0
      this.width = this.$refs.grid?.offsetWidth || 0
    })
  },
  methods: {
    async selectPresent(answer) {
      if (!this.isPresentClickable) return
      this.working = true
      try {
        await this.addPlay({ answer })
        await NavigationService.next(this.orgID, this.gameID)
      } catch (e) {
        console.error(e)
      }
      this.working = false
    },
    async stealPresent(answer) {
      const play = this.plays.find(play => play.answer?.image === answer?.image)
      if (!play) throw new Error("Something went wrong")
      if (this.working) return
      this.working = true
      try {
        await this.addPlay({
          id: play.id,
          answer,
          steals: (parseInt(play.steals) || 0) + 1,
          stolenFromUserID: play.userID
        })
        await NavigationService.next(this.orgID, this.gameID)
      } catch (e) {
        console.error(e)
      }
      this.working = false
    },
    async addPlay(play) {
      const user = this.currentUser
      await this.$store.dispatch("addPlay", {
        missionID: this.missionID,
        points: 0,
        score: 0,
        steals: 0,
        result: true,
        behavior: Mission.WhiteElephant,
        firstname: user?.firstname,
        lastname: user?.lastname,
        teamID: 0,
        userID: user?.id,
        timestamp: Date.now(),
        ...play
      })
    },
    getStyle(multiplier = 1) {
      const height = parseInt(this.length)
      const width = parseInt(height * multiplier)

      return `width: ${width}px; height: ${height}px;`
    },
    speak(text) {
      const message = new SpeechSynthesisUtterance(text)
      message.rate = 0.9
      message.volume = this.volume
      window.speechSynthesis.speak(message)
    },
    handleResize: debounce(function () {
      this.height = 0
      this.width = 0
      this.$nextTick(() => {
        this.height = this.$refs.grid?.offsetHeight || 0
        this.width = this.$refs.grid?.offsetWidth || 0
      })
    }, 500),
    download() {
      const title = this.game?.externalName || this.game?.name || ""
      const plays = this.plays.map(play => ({
        ...play,
        roomName: title
      }))
      const accessor = [
        {
          access: "firstname",
          value: "First Name"
        },
        {
          access: "lastname",
          value: "Last Name"
        },
        {
          access: obj => obj?.answer?.label ?? "",
          value: "Gift Label"
        },
        {
          access: obj => obj?.answer?.image ?? "",
          value: "Gift Image"
        },
        {
          access: "roomName",
          value: "Room Name"
        },
        {
          access: obj => obj?.answer?.url ?? "",
          value: "Gift Url"
        }
      ]
      const headers = accessor.map(({ value }) => value)
      const body = plays.map(play => {
        return accessor.map(({ access }) =>
          JSON.stringify(
            access instanceof Function ? access(play) : play?.[access] || ""
          )
        )
      })
      const csv = [headers, ...body].join("\r\n")
      const data = new Blob([csv], {
        type: "text/csv;charset=utf-8;"
      })
      const filename = `${this.game?.externalName || this.game?.name}__${
        this.mission?.title || "undefined"
      }.csv`

      File.download(data, filename)
    }
  }
})
</script>

<style lang="scss">
.white-elephant {
  $block: &;

  &__download-btn {
    font-family: "Sofia Pro";
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;
  }

  &__sidebar {
    background-color: #23222a;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    flex-basis: 272px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__title {
    font-family: "Right Grotesk", sans-serif;
    font-weight: 900;
    line-height: 38px;
    padding-left: 36px;
    padding-right: 16px;
    text-transform: uppercase;
    text-align: left;
    color: #fff;
    font-size: 32px;
  }

  &__presents {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .present {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    transition: 0.3s;

    &--selectable {
      cursor: pointer;
      opacity: 1;

      &:hover {
        filter: drop-shadow(0px 0px 40px #e0d4f9);
      }
    }
  }

  &__end-video {
    padding-top: 57%;
    position: relative;
    video {
      position: absolute;
      object-fit: contain;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .white-elephant-grid {
    position: relative;
    padding: 16px;
    max-width: 100%;
    max-height: 100%;
    text-align: left;

    &__scrollable {
      overflow-y: auto;
      display: flex;
      align-items: flex-start;
      position: relative;
    }

    &__grid {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      min-height: 100%;
      min-width: 100%;
    }

    &__sqaure {
      display: inline-block;
      text-align: center;
      /* overflow: hidden; */

      &--selected {
        border-color: $primary-accent-color !important;
        z-index: 1;
      }
    }

    /* &__download {
      pointer-events: all;
      margin-top: 20px;
    } */
  }

  .present-grid {
    flex: 1;
    overflow-y: auto;
    padding-left: 20px;
    padding-top: 80px;

    &__cnt {
      display: flex;
      flex-wrap: wrap;
      height: 0;
    }

    &__item {
      width: 50%;
      flex-basis: 50%;
      display: flex;
      justify-content: center;
      padding-top: 8px;
      padding-bottom: 8px;

      &:nth-child(1),
      &:nth-child(4),
      &:nth-child(9),
      &:nth-child(12) {
        width: 100%;
        flex-basis: 100%;
        padding: 0;
        margin-top: -16px;
        margin-bottom: -16px;

        .present-grid__item-cnt {
          width: 30%;
        }
      }
    }

    &__item-cnt {
      min-width: 60px;
      width: 60%;
      position: relative;

      &::before {
        display: block;
        content: "";
        padding-top: 100%;
      }
    }
  }
}
</style>
