
































































// @ts-nocheck
import Vue from "vue"
import { mapGetters, mapActions } from "vuex"

import UserName from "@/components/GroupTeams/Common/User/UserName.vue"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia.vue"
import GameCountdown from "@/components/GroupTeams/Common/GameCountdown"
import { RtbButton } from "@/components/ui"

export default Vue.extend({
  name: "MeetingCard",
  components: {
    UserName,
    UserMedia,
    RtbButton,
    GameCountdown
  },
  props: {
    endTime: {
      type: Number,
      default: null
    },
    currentPresent: {
      type: Object
    },
    cardText: {
      required: false,
      type: String
    }
  },
  computed: {
    ...mapGetters("auth", ["role", "user", "isHost"]),
    ...mapGetters(["gameHost"]),
    ...mapGetters(["actualGame"]),
    mission() {
      return this.$store.getters.getCurrentMission
    },
    viewBox() {
      if (this.currentPresent) {
        return `0 0 500 90`
      }

      if (this.$slots.button) {
        return `0 0 500 120`
      }

      return `0 0 500 200`
    }
  },
  methods: {
    ...mapActions(["updateUser"]),
    zoomPresent() {
      this.$bus.$emit("zoomMissionAssetPhoto", {
        visible: true,
        photo: this.currentPresent.image,
        title: this.currentPresent.label
      })
    }
  }
})
