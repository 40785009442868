var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gift-exchange__gift" },
    [
      _c("div", { staticClass: "gift-exchange__gift-image" }, [
        _c("img", { attrs: { src: _vm.gift.image } }),
        _c("div", { staticClass: "gift-exchange__text-box" }, [
          _c("div", { staticClass: "gift-exchange__gift-label" }, [
            _vm._v(" " + _vm._s(_vm.gift.label) + " "),
          ]),
        ]),
        _vm.steals
          ? _c(
              "div",
              {
                staticClass: "gift-exchange__steals-label",
                class: { "pink--text": _vm.steals >= _vm.maxSteals },
              },
              [
                _vm.steals < _vm.maxSteals
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-right": "5px",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.steals) + " ")]
                    )
                  : _c("SvgIcon", { attrs: { name: "lock" } }),
                _c(
                  "span",
                  { staticClass: "gift-exchange__steals-label-text" },
                  [
                    _vm.steals < _vm.maxSteals
                      ? [_vm._v(" Steal ")]
                      : [_vm._v(" Locked ")],
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "gift-exchange__gift-controls" },
        [
          _vm.steals < _vm.maxSteals && _vm.canSteal
            ? _c(
                "RtbButton",
                {
                  staticClass: "gift-exchange__steal",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("stealPresent")
                    },
                  },
                },
                [_vm._v(" steal ")]
              )
            : _vm._e(),
          !_vm.canSteal && _vm.steals && _vm.steals < _vm.maxSteals
            ? _c("span", { staticClass: "gift-exchange__steal-info" }, [
                _vm._v(" This gift can only be stolen one more time "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "RtbButton",
        {
          staticClass: "gift-exchange__gift-zoom",
          attrs: { size: "sm", variant: "icon", outline: "" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.zoom.apply(null, arguments)
            },
          },
        },
        [_c("SvgIcon", { attrs: { name: "zoom-in", size: "small" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }