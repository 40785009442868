var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "we-card" },
    [
      _c(
        "v-layout",
        { staticClass: "we-card__body" },
        [
          !!_vm.gameHost
            ? _c(
                "v-flex",
                { staticClass: "we-card__host", attrs: { xs4: "" } },
                [
                  _c("UserMedia", {
                    staticClass: "we-card__host-media",
                    attrs: {
                      identity: _vm.gameHost.id,
                      imageUrl: _vm.gameHost.image,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "we-card__host-name" },
                    [
                      _c("UserName", {
                        attrs: {
                          firstname: _vm.gameHost.firstname,
                          lastname: _vm.gameHost.lastname,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { staticClass: "we-card__info", attrs: { "d-flex": "" } },
            [
              !!_vm.endTime
                ? _c("GameCountdown", {
                    staticClass: "we-card__countdown",
                    attrs: { endTime: _vm.endTime },
                  })
                : _vm._e(),
              _c("div", { staticClass: "we-card__text" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "100%",
                      viewBox: _vm.viewBox,
                      preserveAspectRatio: "xMinYMin meet",
                    },
                  },
                  [
                    _c(
                      "foreignObject",
                      { attrs: { width: "100%", height: "100%" } },
                      [
                        _c("div", {
                          staticClass: "we-card__message",
                          attrs: { xmlns: "http://www.w3.org/1999/xhtml" },
                          domProps: { innerHTML: _vm._s(_vm.cardText) },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._t("button"),
              _vm.currentPresent
                ? _c("div", { staticClass: "we-card__present" }, [
                    _c(
                      "div",
                      { staticClass: "we-card__present-image" },
                      [
                        _c("img", { attrs: { src: _vm.currentPresent.image } }),
                        _c("div", { staticClass: "we-card__text-box" }, [
                          _c("div", { staticClass: "we-card__present-label" }, [
                            _vm._v(
                              " " + _vm._s(_vm.currentPresent.label) + " "
                            ),
                          ]),
                        ]),
                        _c(
                          "RtbButton",
                          {
                            staticClass: "we-card__present-zoom",
                            attrs: { size: "sm", variant: "icon", outline: "" },
                            on: { click: _vm.zoomPresent },
                          },
                          [
                            _c("SvgIcon", {
                              attrs: { name: "zoom-in", size: "small" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }