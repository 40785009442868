
















import { defineComponent } from "@vue/composition-api"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import GiftExchangeUI from "@/components/GroupTeams/Common/Player/Parts/GiftExchangeUI.vue"

export default defineComponent({
  name: "GiftExchangeUserCard",
  components: {
    GiftExchangeUI,
    AppUserCard
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    canSteal: {
      type: Boolean,
      default: false
    }
  }
})
